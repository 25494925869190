import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import api from '../../axiosConfig'

const Realtime = ({selectedCollections, selectedVideos}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
      const fetchRealtimeData = async () => {
          try {
            if (selectedVideos?.length > 0) {
                const res = await api.post(`/analytics/realtime`, {
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      collectionIds: selectedCollections?.map(collection => collection.id),
                      videoIds: selectedVideos?.map(video => video.id),
                    })
                  });
                  const newData = await res.data;
                  setData(prevData => {
                      let updatedData = prevData.concat(newData);
                      if (updatedData.length > 14) {
                          updatedData = updatedData.slice(1);
                      }
                      return updatedData;
                  });
            } 
          } catch (error) {
              console.error('Error fetching realtime data: ', error);
          }
      };

      fetchRealtimeData();
      const intervalId = setInterval(fetchRealtimeData, 60000); // 60000 ms = 1 minute

      return () => clearInterval(intervalId); // cleanup on component unmount
  }, [selectedCollections, selectedVideos]);

  const chartData = data.map((item, index) => ({
    name: `Data ${index + 1}`,
    'Total Views': item['Total Views'],
  }));

  return (
    <div className='realtime-root'>
      <div className='realtime-header'>
          <div className='header-content'>
            <p className='realtime-title'>Real Time Views/Min</p>
            {/* <button className='view-realtime'>{"View realtime ->"}</button> */}
          </div>
          <span className="view-count">
              {data && data.length > 0 ? 
                  (function() {
                      const avgViews = data.reduce((sum, item) => sum + item["Total Views"], 0) / data.length;
                      if (avgViews >= 1e6) {
                          return (avgViews / 1e6).toFixed(2) + 'M';
                      } else if (avgViews >= 1e3) {
                          return (avgViews / 1e3).toFixed(2) + 'K';
                      } else {
                          return avgViews.toFixed(2);
                      }
                  })()
                  : 0
              }
          </span>        
      </div>
      <BarChart width={460} height={150} data={chartData}>
        <CartesianGrid vertical={false} />
        <YAxis
            orientation="right"
            ticks={[0, (Math.max(...data.map(item => item['Total Views'])) / 3).toFixed(2), (Math.max(...data.map(item => item['Total Views'])) * 2 / 3).toFixed(2), Math.max(...data.map(item => item['Total Views']))]}
            tickFormatter={(tick) => {
                if (tick >= 1e6) {
                return (tick / 1e6).toFixed(0) + 'M';
                } else if (tick >= 1e3) {
                return (tick / 1e3).toFixed(0) + 'K';
                } else {
                return tick;
                }
            }}
            axisLine={false} tickLine={false}
        />        
        <Tooltip />
        <Bar dataKey="Total Views" fill="blue"  radius={[5, 5, 0, 0]} />
      </BarChart>
      <div className='realtime-filters'>
        <b className='realtime-filter'>{"Country"}</b>
        <b className='realtime-filter'>{"Users"}</b>
      </div>
      <div className="country-distribution">
          {data && data.length > 0 && (() => {
            const countryTotals = data.reduce((totals, { 'Views By Country': views }) => {
                views.forEach(({ country, y }) => {
                    totals[country] = (totals[country] || 0) + y;
                });
                return totals;
            }, {});

            const totalViews = Object.values(countryTotals).reduce((sum, count) => sum + count, 0);
            const sortedCountries = Object.entries(countryTotals).sort(([, a], [, b]) => b - a);
            const topCountries = sortedCountries.slice(0, 3);
            const otherViews = sortedCountries.slice(3).reduce((sum, [, count]) => sum + count, 0);
            return (
                <>
                  {topCountries.map(([country, count]) => {
                      var percentage = ((count / totalViews) * 100).toFixed(2);
                      if(percentage === "NaN"){
                        percentage = 0;
                      }
                      return (
                          <div key={country} className="country-item">
                              <span>{country}</span>
                              <span>{percentage} %</span>
                          </div>
                      );
                  })}
                  {otherViews > 0 && (() => {
                      const percentage = ((otherViews / totalViews) * 100).toFixed(2);
                      return (
                          <div key="Others" className="country-item">
                              <span>Others</span>
                              <span>{percentage} %</span>
                          </div>
                      );
                  })()}
              </>
            );
        })()}
      </div>
    </div>
  )
}

export default Realtime