import React, { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import api from '../../axiosConfig.js';
import { Eye, EyeSlash } from 'iconsax-react';
import { toast } from 'react-toastify';


const PasswordReset = () => {
  const { uid, token } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  }
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password === '') {
      toast.error("Password Can't be empty");
      return;
    }
    else if (confirmPassword === '') {
      toast.error("Confirm Password Can't be empty");
      return;
    }
    else if (password !== confirmPassword) {
      toast.error("Password are not matching");
      return;
    }
    try {
      const response = await api.post('/auth/password/reset/confirm/', {
        new_password1: password,
        new_password2: confirmPassword,
        uid: uid,
        token: token
      });
      if (response.status === 200) {
        console.log(response.data);
        toast.success(response.data.detail);
        navigate("/login");
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      // toast.error('Error in resetting password. Please try again.');
    }
  };

  return (
    <div className="w-full flex">
      <div className='left-container w-3/5 h-screen bg-slate-200 rounded-tr-3xl rounded-br-3xl'></div>
      <div className="form-container w-2/5 flex flex-col items-center justify-center">
        <div className='flex flex-col'>
          <div className='register-head flex flex-col items-start gap-y-4 mb-12'>
            <div className='greetings'>
              <h3 className='text-3xl font-semibold'>Welcome to ChillyPepper 👋</h3>
            </div>
            <div className='register-tagline'>
              <h3 className='text-base font-normal'>Kindly Enter the New Password</h3>
            </div>
          </div>
          <div className="register-form-container">
            <form onSubmit={handleSubmit} className='login-container flex flex-col'>
              {/* password */}
              <div className='password-field-container pb-5'>
                <h4 className='text-base font-medium pb-2 text-[--secondary-txt]'>Password</h4>
                <div className='relative'>
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={handlePasswordChange}
                    className={`p-5 border border-[--app-border] rounded-md w-full outline-none font-semibold`}
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute top-1/2 right-2 transform -translate-y-1/2 text-[--secondary-txt] focus:outline-none"
                  >
                    {showPassword ? (<EyeSlash size="24" />) : (<Eye size="24" />)}
                  </button>
                </div>
              </div>

              {/* confirm password */}
              <div className='confirm-password-field-container pb-5'>
                <h4 className='text-base font-medium pb-2 text-[--secondary-txt]'>Confirm Password</h4>
                <div className='relative'>
                  <input
                    type={showPassword ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Confirm your password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    className={`p-5 border border-[--app-border] rounded-md w-full outline-none font-semibold`}
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute top-1/2 right-2 transform -translate-y-1/2 text-[--secondary-txt] focus:outline-none"
                  >
                    {showPassword ? (<EyeSlash size="24" />) : (<Eye size="24" />)}
                  </button>
                </div> </div>
              <div className='flex flex-col items-center justify-center w-full pt-6 gap-y-5'>
                <button className="lr-button p-5 bg-[--accent-1] w-full rounded-md text-white font-medium" type="submit">Change Password</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
};

export default PasswordReset;
