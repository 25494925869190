import React, {useState, useEffect} from 'react'
import api from '../../axiosConfig'
import {getPreSignedUrl} from "./../create-video/s3-upload";

const TopPerf = ({selectedCollections, selectedVideos, startDate, endDate}) => {
    const [data, setData] = useState(null);
    const [coverImages, setCoverImages] = useState([]);

    useEffect(() => {
        const fetchTopVideosData = async () => {
            try {
                if (selectedVideos?.length > 0) {
                    const res = await api.post(`/analytics/videos`, {
                        headers: {
                          'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                          collectionIds: selectedCollections?.map(collection => collection.id),
                          videoIds: selectedVideos?.map(video => video.id),
                          startDate: startDate.toISOString(),
                          endDate: endDate.toISOString()
                        })
                      });            
                      const data = await res.data
                      setData(data)
                }               
            }
            catch (error) {
                console.log(error);
            }
        }
        fetchTopVideosData()
    }, [selectedCollections, selectedVideos, startDate, endDate]);

    useEffect(() => {
        const fetchCoverImages = async () => {
          if (selectedVideos?.length > 0) {
            const urls = await Promise.all(
              selectedVideos.map(async (element) => {
                const url = await getPreSignedUrl(element.coverImage);
                return { id: element.id, coverImage: url.url };
              })
            );
            const coverImagesMap = urls.reduce((acc, curr) => {
              acc[curr.id] = curr.coverImage;
              return acc;
            }, {});
            setCoverImages(coverImagesMap);
          }
        };
        fetchCoverImages();
      }, [selectedVideos]);

  function formatPlayTime(seconds) {
    seconds = Math.trunc(seconds);
    if (seconds < 60) {
        return `${seconds} sec`;
    } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes} min ${remainingSeconds} sec`;
    } else {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return `${hours} hrs ${minutes} min`;
    }
  }

  const sortedData = data && Object.entries(data).sort((a, b) => b[1]['Total Plays'] - a[1]['Total Plays'])

  return (
    <div className="topperf-root">
        <div className="topperf-header">
            <h3>Top Performing Videos</h3>
            <span>Views</span>
            <span>Plays</span>
            <span className='playtime'>Playtime</span>
        </div>
        {data && sortedData.map(([videoId, videoData]) => {
            return (
                <div key={videoId} className="video-item">
                    <img src={coverImages[videoId] || "https://via.placeholder.com/100"} alt="video thumbnail" style={{ width: '180px', height: '100px', objectFit: 'cover' }} />
                    <div className='video-details'>
                      <h4 className='video-title'>{videoData.Title}</h4>  
                      <h4 className='video-description'>{videoData.Description}</h4>
                    </div>  
                    <div className="video-metrics">
                        <span className='video-metric'>{videoData['Total Views']}</span>
                        <span className='video-metric'>{videoData['Total Plays']}</span>
                        <span className='video-metric'>{formatPlayTime(videoData['Playtime'])}</span>                    </div>
                </div>
            )
            
        })}
        {/* <button className="view-more-button">VIEW MORE</button> */}
    </div>
  )
}

export default TopPerf