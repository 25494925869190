import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../../axiosConfig';
import { toast } from 'react-toastify';

const VerifyEmail = () => {
    const {key} = useParams();
    const [isVerify, setIsVerify]  = useState(false);

    const handleEmailVerification = async ()=>{
        const res = await api.post('/auth/registration/verify-email/', { key: key }).then(function (response) {
            toast.success("Email is verified sucessfully! Kindly navigate to login")
            setIsVerify(true);
          }).catch(function (error, message) {
            console.log(error);
          });
          console.log(res);
          return res;
    }
    const handleLoginClick = ()=>{
        window.location.href = "/";
    }
  return (
    <div>
       <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="max-w-md w-full bg-white p-8 rounded shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Email Verification</h2>
            <p className="mb-6">Please Click the below button to verify the Email</p>
            <div className="flex justify-between">
            
            
            {isVerify?  
                <button className="profile-section-button px-3 py-2 rounded-2xl bg-[--accent-1] text-white font-semibold w-fit mt-2" onClick={handleLoginClick}>
                    Login
                </button>:
                <button className="profile-section-button px-3 py-2 rounded-2xl bg-[--accent-1] text-white font-semibold w-fit mt-2" onClick={handleEmailVerification}>
                    Verify Email
                </button>}
            </div>
            </div>
        </div>
    </div>
  )
}

export default VerifyEmail
