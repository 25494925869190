const GoogleFlatIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.4397 11.715H24.4999V11.6666H13.9999V16.3333H20.5933C19.6314 19.0498 17.0467 20.9999 13.9999 20.9999C10.1342 20.9999 6.99992 17.8657 6.99992 13.9999C6.99992 10.1342 10.1342 6.99992 13.9999 6.99992C15.7843 6.99992 17.4078 7.67308 18.6438 8.77267L21.9438 5.47275C19.8601 3.53084 17.0729 2.33325 13.9999 2.33325C7.557 2.33325 2.33325 7.557 2.33325 13.9999C2.33325 20.4428 7.557 25.6666 13.9999 25.6666C20.4428 25.6666 25.6666 20.4428 25.6666 13.9999C25.6666 13.2177 25.5861 12.4541 25.4397 11.715Z"
        fill="#FFC107"
      />
      <path
        d="M3.67847 8.56967L7.51155 11.3808C8.54872 8.81292 11.0606 6.99992 14 6.99992C15.7844 6.99992 17.4078 7.67309 18.6439 8.77267L21.9438 5.47275C19.8601 3.53084 17.073 2.33325 14 2.33325C9.5188 2.33325 5.63263 4.86317 3.67847 8.56967Z"
        fill="#FF3D00"
      />
      <path
        d="M14 25.6666C17.0135 25.6666 19.7517 24.5133 21.8219 22.6379L18.2111 19.5824C17.0004 20.5031 15.521 21.0011 14 20.9999C10.9655 20.9999 8.38894 19.065 7.41827 16.3647L3.61377 19.296C5.5446 23.0742 9.46577 25.6666 14 25.6666Z"
        fill="#4CAF50"
      />
      <path
        d="M25.4397 11.7152H24.5V11.6667H14V16.3334H20.5934C20.1333 17.6263 19.3045 18.7561 18.2093 19.5832L18.2111 19.582L21.8219 22.6375C21.5664 22.8697 25.6667 19.8334 25.6667 14.0001C25.6667 13.2178 25.5862 12.4542 25.4397 11.7152Z"
        fill="#1976D2"
      />
    </svg>
  );
};

const AppleIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1235_740)">
        <path
          d="M15.3751 0.0110096C15.3284 -0.0412404 13.644 0.0316347 12.1783 1.62251C10.7125 3.21201 10.938 5.03526 10.971 5.08201C11.004 5.12876 13.061 5.20163 14.3741 3.35226C15.6873 1.50288 15.4219 0.0646347 15.3751 0.0110096ZM19.9319 16.1439C19.8659 16.0119 16.735 14.4471 17.0265 11.4386C17.318 8.43014 19.3296 7.60376 19.3613 7.51439C19.3929 7.42501 18.5404 6.42814 17.637 5.92351C16.9736 5.56807 16.2396 5.36426 15.4879 5.32676C15.3394 5.32264 14.8238 5.19614 13.7636 5.48626C13.0651 5.67738 11.4908 6.29614 11.0576 6.32089C10.6231 6.34564 9.33065 5.60314 7.94052 5.40651C7.0509 5.23463 6.10765 5.58664 5.43252 5.85751C4.75877 6.12701 3.47727 6.89426 2.58077 8.93339C1.68427 10.9711 2.15315 14.1996 2.48865 15.2034C2.82415 16.2071 3.34802 17.8489 4.23902 19.0479C5.03102 20.4009 6.08152 21.34 6.52015 21.659C6.95877 21.978 8.19627 22.1898 9.05427 21.7511C9.74452 21.3276 10.9903 21.0843 11.4825 21.1021C11.9734 21.12 12.9414 21.3139 13.9328 21.8433C14.7179 22.1141 15.4604 22.0014 16.2043 21.6989C16.9481 21.395 18.0248 20.2428 19.2815 17.9066C19.7582 16.8204 19.975 16.2328 19.9319 16.1439Z"
          fill="black"
        />
        <path
          d="M15.3751 0.0110096C15.3284 -0.0412404 13.644 0.0316347 12.1783 1.62251C10.7125 3.21201 10.938 5.03526 10.971 5.08201C11.004 5.12876 13.061 5.20163 14.3741 3.35226C15.6873 1.50288 15.4219 0.0646347 15.3751 0.0110096ZM19.9319 16.1439C19.8659 16.0119 16.735 14.4471 17.0265 11.4386C17.318 8.43014 19.3296 7.60376 19.3613 7.51439C19.3929 7.42501 18.5404 6.42814 17.637 5.92351C16.9736 5.56807 16.2396 5.36426 15.4879 5.32676C15.3394 5.32264 14.8238 5.19614 13.7636 5.48626C13.0651 5.67738 11.4908 6.29614 11.0576 6.32089C10.6231 6.34564 9.33065 5.60314 7.94052 5.40651C7.0509 5.23463 6.10765 5.58664 5.43252 5.85751C4.75877 6.12701 3.47727 6.89426 2.58077 8.93339C1.68427 10.9711 2.15315 14.1996 2.48865 15.2034C2.82415 16.2071 3.34802 17.8489 4.23902 19.0479C5.03102 20.4009 6.08152 21.34 6.52015 21.659C6.95877 21.978 8.19627 22.1898 9.05427 21.7511C9.74452 21.3276 10.9903 21.0843 11.4825 21.1021C11.9734 21.12 12.9414 21.3139 13.9328 21.8433C14.7179 22.1141 15.4604 22.0014 16.2043 21.6989C16.9481 21.395 18.0248 20.2428 19.2815 17.9066C19.7582 16.8204 19.975 16.2328 19.9319 16.1439Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1235_740">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const FacebookIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1235_744)">
        <path
          d="M32 16C32 7.1635 24.8365 0 16 0C7.1635 0 0 7.1635 0 16C0 23.986 5.851 30.6054 13.5 31.8056V20.625H9.4375V16H13.5V12.475C13.5 8.465 15.8888 6.25 19.5435 6.25C21.294 6.25 23.125 6.5625 23.125 6.5625V10.5H21.1075C19.1199 10.5 18.5 11.7334 18.5 12.9987V16H22.9375L22.2281 20.625H18.5V31.8056C26.149 30.6054 32 23.9861 32 16Z"
          fill="#1877F2"
        />
        <path
          d="M22.2281 20.625L22.9375 16H18.5V12.9987C18.5 11.7332 19.1199 10.5 21.1075 10.5H23.125V6.5625C23.125 6.5625 21.294 6.25 19.5434 6.25C15.8888 6.25 13.5 8.465 13.5 12.475V16H9.4375V20.625H13.5V31.8056C14.327 31.9352 15.1629 32.0002 16 32C16.8371 32.0002 17.673 31.9353 18.5 31.8056V20.625H22.2281Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1235_744">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const TwitterIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1235_747)">
        <path
          d="M24.5 0H7.5C3.35786 0 0 3.35786 0 7.5V24.5C0 28.6421 3.35786 32 7.5 32H24.5C28.6421 32 32 28.6421 32 24.5V7.5C32 3.35786 28.6421 0 24.5 0Z"
          fill="white"
        />
        <path
          d="M24.5 0H7.5C3.35786 0 0 3.35786 0 7.5V24.5C0 28.6421 3.35786 32 7.5 32H24.5C28.6421 32 32 28.6421 32 24.5V7.5C32 3.35786 28.6421 0 24.5 0Z"
          fill="#1D9BF0"
        />
        <path
          d="M24.9465 11.4264C24.9603 11.6247 24.9603 11.8231 24.9603 12.0234C24.9603 18.123 20.3168 25.1577 11.8259 25.1577V25.154C9.31752 25.1576 6.86113 24.4392 4.75 23.0846C5.11475 23.1285 5.48125 23.1504 5.84875 23.1512C7.92775 23.1529 9.94694 22.4556 11.5819 21.1714C10.6186 21.1532 9.68509 20.8344 8.91185 20.2597C8.13862 19.6849 7.56432 18.8829 7.26925 17.9657C7.96085 18.0991 8.67382 18.0719 9.35325 17.8862C7.19975 17.4511 5.65037 15.559 5.65037 13.3615V13.303C6.29236 13.6606 7.01085 13.8587 7.74538 13.8807C5.71713 12.5251 5.09188 9.82674 6.31663 7.71699C7.47554 9.14312 8.92149 10.3095 10.5606 11.1404C12.1996 11.9712 13.9952 12.448 15.8305 12.5397C15.6475 11.752 15.6745 10.9301 15.9089 10.1561C16.1432 9.38215 16.5767 8.68327 17.1659 8.12937C19.0251 6.38162 21.9493 6.47124 23.697 8.32949C24.7308 8.1254 25.7221 7.74642 26.6284 7.20887C26.2838 8.2777 25.5626 9.18496 24.5991 9.76187C25.5142 9.65406 26.4078 9.40911 27.25 9.03524C26.6305 9.96299 25.8505 10.7726 24.9465 11.4264Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1235_747">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { GoogleFlatIcon, AppleIcon, FacebookIcon, TwitterIcon };
