import './settings.css'
import React, { useState, useEffect, useRef } from "react";



const FileUpload = ({ onUpload }) => {
  const [highlight, setHighlight] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const inputRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    setHighlight(true);
  };

  const handleDragLeave = () => {
    setHighlight(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setHighlight(false);
    const file = e.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleInputChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  return (
    <React.Fragment>
      {!selectedFile && (
        <div
          className={`settings-file-upload ${highlight ? 'highlight' : ''}`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={() => inputRef.current.click()}
        >
          <input
            type="file"
            ref={inputRef}
            className="file-input"
            onChange={handleInputChange}
            multiple
          />
          <h3> Add Company Logo</h3>
          <h4> Drag Image File here or Click to upload</h4>

        </div>
      )}
      {selectedFile && (
        <div className="file-info">
          <p>Selected File: {selectedFile.name}</p>
          <div><button className="modalContButton" onClick={() => setSelectedFile(null)}>Change File</button>
            <button className="modalContButton" onClick={() => console.log("Continue")}>Continue</button></div>
        </div>
      )}
    </React.Fragment>
  );
};



export default function Settings() {
  const [tab, setTab] = useState(1);
  useEffect(() => { }, [tab])
  return (
    <React.Fragment>

      <div className="settingshead">
        <div className="headTitle"><h1>Settings</h1></div>
        <div className='headUser'><h4>{localStorage.getItem('username') ? localStorage.getItem('username') : ""}</h4></div>
      </div>
      <div className="settings-Body">
        <div className="settingsHead">
          <div className="settingsTabsSwitchContainer">
            <div onClick={() => { setTab(1) }} className={tab === 1 ? '' : 'inactive'}>General</div>
            <div onClick={() => { setTab(2) }} className={tab === 2 ? '' : 'inactive'}>Installation</div>

          </div>
          <button disabled={true}>Save Changes</button>
        </div>
        <div className={tab === 1 ? 'settingssections' : 'inactive-tab'}>
          <div className="bodySections">
            <div className="headings">Company Details</div>
            <input type="text" className="settingsInput" placeholder='Company' ></input>
            <input type="text" className="settingsInput" placeholder='Company Url' ></input>
          </div>
          <div className="bodySections">
            <div className="headings">Company Logo</div>
            <FileUpload></FileUpload>
          </div>
          <div className="bodySections">


          </div>

        </div>
        <div className={tab === 2 ? 'settingssections' : 'inactive-tab'}>
          <div className="bodySections">
            <div className="headings">Api Keys</div>
            <div className="ProfileSectionButton">Generate Api Keys</div>


          </div>
          <div className="bodySections">
            <div className="headings">Need Help?</div>
            <h4 className='normal'>We've got a bunch of resources to help you each step of the way.</h4>
            <div className="ProfileSectionButton">Installation Guide</div>


          </div>
          <div className="bodySections">


          </div>

        </div>
      </div>
    </React.Fragment>
  )
}
