import api from '../../axiosConfig.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


async function login(email, password) {
    const res = await api.post('/auth/login/', { email: email, password: password }).then(function (response) {
    localStorage.setItem('accessToken', response.data.access);
    localStorage.setItem('refreshToken', response.data.refresh);
    localStorage.setItem('username', response.data.user.username);
    window.location.reload();
  }).catch(function (error) {
    console.log("Login error:", error);
    console.error(error);
  });
  console.error(res);
  return res;
}

async function register(username, password1, email, password2) {
  const res = await api.post('/auth/registration/', { username: username, password1: password1, password2: password2, email: email}).then(function (response) {
    window.location.href = "/emailverification"
  }).catch(function (error) {
    console.log(error);
  });
  console.log(res);
  return res;
}

async function logout() {
  try {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('username');
    const logoutResponse = await api.post('/auth/logout/');

    if (logoutResponse.status === 200) {
      console.log('Successfully logged out');
    }
    window.location.reload();
  } catch (error) {
    console.error('Error logging out:', error);
  }
}

async function updatePassowrd(email) {
  const res = await api.post('/auth/password/reset/', { email: email }).then(function (response) {
    toast.success(response.data.detail);
  }).catch(function (error) {
    console.log(error);
  });
  console.log(res);
  return res;
}

export { login, register, logout, updatePassowrd }