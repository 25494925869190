import React, {useState} from 'react';
import { FaChevronDown } from 'react-icons/fa';

const Metrics = ({data, index, metrics, selectedMetrics, onChange, displayIndex, setDisplayIndex}) => {
  const [selectDropdownOpen, setSelectDropdownOpen] = useState(false);
  const totalCount = data ? data[selectedMetrics[index]]["data"] ? data[selectedMetrics[index]]["data"].map(dayData => dayData["y"]).reduce((a, b) => a + b, 0) : 0 : 0;
  const totalCountStr = totalCount >= 1000000 ? `${(totalCount / 1000000).toFixed(2)}M` : totalCount >= 1000 ? `${(totalCount / 1000).toFixed(2)}K` : totalCount;

  return (
    <div className="overall-header" onClick={() => setDisplayIndex(index)} style={displayIndex === index ? {borderTop: '5px solid blue'} : null}>
        <p>{totalCountStr}</p>
        <button className='metric-selector' onClick={() => setSelectDropdownOpen(!selectDropdownOpen)}>
            {selectedMetrics[index]}
            <FaChevronDown className='metric-selector-dropdown-icon'/>
        </button>
        {selectDropdownOpen && (
            <div className='metric-selector-dropdown'>
                {Object.values(metrics).map((metric, idx) => (
                    <button key={`metric-${idx}`} onClick={() => {
                        onChange({...selectedMetrics, [index]: metric});
                    }} style={selectedMetrics[index] === metric ? {backgroundColor: '#f4f4f4'} : null}>
                        {metric}
                    </button>
                ))}
            </div>
        )}
    </div>
  )
}

export default Metrics