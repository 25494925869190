import api from "../../axiosConfig";

export async function deleteVideo (id, callback) {
    try {
        const response = await api.delete(`/videos/${id}/`);
        callback();
        return response.data;
    } catch (err) {
        console.error("Error deleting video", err);
    }
};