import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import { CloseCircle } from 'iconsax-react';
import {toast} from "react-toastify"
import axios from 'axios';

import './upload.css';
import api from "../../axiosConfig.js";
import { putPreSignedUrl, uploadFileToS3Bucket } from '../create-video/s3-upload.js';
import { CiViewList } from "react-icons/ci";

// displaying video previews
const VideoPreview = ({ file, onRemove }) => {
  const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2); // Calculate file size in MB

  return (
    <div className="file-preview flex flex-col items-center justify-center p-2 border rounded-lg max-w-80 relative">
      <video className='object-cover'>
        <source src={URL.createObjectURL(file)} type={file.type} />
        Your browser does not support the video tag.
      </video>
      <button className="absolute top-3 right-3 bg-white text-red-500 rounded-full" onClick={() => onRemove(file)}>
        <CloseCircle size={'1.5rem'} />
      </button>
      <div className='uploaded-file-info max-w-full break-words'>
        <p className='font-medium overflow-hidden whitespace-nowrap overflow-ellipsis'>{file.name}</p>
        <p className='font-medium'>{fileSizeInMB} MB</p> {/* change this later */}
      </div>
    </div>
  );
};

export default function Upload() {
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const [instaUser, setInstaUser] = useState('');
  const [instaVids, setInstaVids] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [connect, setConnect] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileCount, setFilecount] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [showInstaUploadProgress, setShowInstaUploadProgress] =useState(false);
  const handleInstagramConnect = () => {
    setConnect(true);
  }

  const handleFileBrowsing = (event) => {
    const files = event.target.files;
    setUploadedVideos([...uploadedVideos, ...files]);
    console.log(uploadedVideos)
  };

  const handleFileUpload = (e) => {
    const files = e.target.files;
    setUploadedVideos([...uploadedVideos, ...files]);
  }
  const handleVideoUpload = async () => {
    setShowProgress(true);
  
    for (let i = 0; i < uploadedVideos.length; i++) {
      const file = uploadedVideos[i];
      setProgress(0);
      setFilecount(i + 1);
  
      try {
        const url = await putPreSignedUrl(file.name);
  
        const updateProgress = (progress) => {
          setProgress(progress);
        };
  
        const res = await uploadFileToS3Bucket(file, url.url, updateProgress);
  
        const response = await api.post("/video-library/", {
          title: "This is a dummy title of uploads",
          video: url.key,
          video_type: 1
        });
  
      } catch (error) {
        toast.error("Error uploading video: " + error.message);
      }
    }
    setShowProgress(false);
    toast.success("Videos Uploaded Successfully");
    setUploadedVideos([]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    setUploadedVideos([...uploadedVideos, ...files]);
  };

  const handleRemoveVideo = async (fileToRemove) => {
    const remainingVideos = uploadedVideos.filter((file) => file!== fileToRemove);
    setUploadedVideos(remainingVideos);
  };

  const handleUserInput = (e) => {
    setInstaUser(e.target.value);
  }

  const fetchReelsFromUsername = async () => {
    setIsLoading(true);

    if (instaVids.length > 0) {
      setInstaVids([]);
    }

    try {
      const response = await api.post(`/auth/instagram/`, {
          "user": instaUser
         }
      );

      if (response.status === 200) {
        console.log("valid response")
        setInstaVids(response.data.posts)
        console.log(instaVids)
      }

    } catch (error) {
      console.error("Error fetching reels: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchReelClick = () => {
    if (instaUser) {
      fetchReelsFromUsername();
    }
  };
  const exportInstaVideo = async (reel) =>{
    const videoResponse = await axios.get(reel, { responseType: 'arraybuffer' });
    const videoBlob = new Blob([videoResponse.data], { type: 'video/mp4' });

    const preSignedUrlData = await putPreSignedUrl('.mp4');
    if (!preSignedUrlData) {
      throw new Error('Failed to get pre-signed URL');
    }
    const url = preSignedUrlData.url;
    await uploadFileToS3Bucket(videoBlob, url, (percentComplete) => {
      setProgress(percentComplete);
      console.log(`Upload progress: ${percentComplete}%`);
    });
    const res = await api.post("/video-library/", {
      title: "This is a dummy title",
      video: preSignedUrlData.key,
      video_type: 2
    });
    // toast.success("Video exported successfully");
    console.log(res);
  }
  const exportSelectedInstaVideos = async () => {
    setShowInstaUploadProgress(true);
    for (let i = 0; i < selectedVideos.length; i++) {
      const file = selectedVideos[i];
      setProgress(0);
      setFilecount(i + 1);
      await exportInstaVideo(file);
    }
    setShowInstaUploadProgress(false);
    toast.success("Selected videos exported successfully");
  };
  const selectAllInstaVideos = async (instaVids)=>{
    if(selectedVideos.length !== instaVids.length){
      setSelectedVideos(instaVids);
    }else{
      setSelectedVideos([]);
    }
  }
  const handleCheckboxChange = (reel) => {
    setSelectedVideos((prevSelected) =>
      prevSelected.includes(reel)
        ? prevSelected.filter((video) => video !== reel)
        : [...prevSelected, reel]
    );
    console.log(selectedVideos);
  };

  // useEffect(() => {
  //   setInstaVids([
  //     "https://instagram.fdel27-3.fna.fbcdn.net/v/t66.30100-16/334326048_852795133337240_1916822551689646602_n.mp4?_nc_ht=instagram.fdel27-3.fna.fbcdn.net&_nc_cat=108&_nc_ohc=sW5CtywGz14Q7kNvgEA9XQz&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AYC7nqddjRmzoHYIRDPpNbF_G5IM0Fyzh3PH6Ao7ZCjaiQ&oe=665E7697&_nc_sid=8b3546",
  //     "https://instagram.fdel27-5.fna.fbcdn.net/v/t66.30100-16/322319405_328475646801394_3159464391874504609_n.mp4?_nc_ht=instagram.fdel27-5.fna.fbcdn.net&_nc_cat=110&_nc_ohc=1golGJ5R9p8Q7kNvgHAFu8v&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AYAECS6whWVnrzvkBXT9opCRmF6mhgnnf81w9OwD9_UMiw&oe=665E9329&_nc_sid=8b3546",
  //     "https://instagram.fdel27-3.fna.fbcdn.net/o1/v/t16/f1/m82/744B6EE4E6258411B6A039473A637FAA_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLmNsaXBzLmMyLjcyMC5iYXNlbGluZSJ9&_nc_ht=instagram.fdel27-3.fna.fbcdn.net&_nc_cat=104&vs=1270548213499116_1531296429&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC83NDRCNkVFNEU2MjU4NDExQjZBMDM5NDczQTYzN0ZBQV92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVAhg6cGFzc3Rocm91Z2hfZXZlcnN0b3JlL0dPM2hseE56bl9BVDFwMEFBS1pqcWJzM1hIOEpicV9FQUFBRhUCAsgBACgAGAAbABUAACaQ%2FsHtwOzHQBUCKAJDMywXQEF7peNT988YEmRhc2hfYmFzZWxpbmVfMV92MREAdf4HAA%3D%3D&ccb=9-4&oh=00_AYBC8VoEUPqFBU8bltEW1ZbliBNo3Ppg2_QxRWnwY9w04w&oe=665E7ACD&_nc_sid=8b3546",
  //   ])
  //   console.log(instaVids)
  // }, [])

  return (
    <div className='px-8 w-full pt-24'>
      <div className="upload-head">
        <div className="upload-head-title flex justify-between">
          <h1 className='upload-title text-4xl font-bold'>VIDEO LIBRARY</h1>
          <Link to="/library">
            <button className='bg-[--accent-1] font-bold text-xl px-4 py-3 text-white rounded-2xl flex items-center justify-center gap-x-2'>
              <CiViewList size="1.75rem" color="#ffffff" /> 
              <span>VIDEO LIST</span>
            </button>
          </Link>
        </div>
        <div className='upload-head-desc'>
          <h3 className='upload-desc text-lg font-medium'>Upload videos to your library to use in your Interactive Videos.</h3>
        </div>
      </div>
      <div className="upload-body w-full">
        <div className="upload-card-container">
          <div className="upload-card mt-8 rounded-xl w-full min-h-64 border border-[--app-border] mb-4" onDragOver={handleDragOver} onDrop={handleDrop}>
            {uploadedVideos.length > 0 ? (
              <>
                <div className='flex justify-between items-center px-4'>
                  <div className='upload-info-container'>
                    <div className='upload-info flex flex-col items-start'>
                      <h3 className='upload-title text-2xl font-bold'>Drag Files or Click Below</h3>
                      <h4 className='upload-note text-xl font-medium'>For best results, use files less than 100MB</h4>
                    </div>
                  </div>
                  <div className='browse-btn-container flex items-center justify-center '>
                    <label htmlFor="file-upload" className="upload-btn mt-4 mx-2 flex items-center justify-center rounded-lg cursor-pointer">
                      <span>Add More</span>
                      <input id="file-upload" type="file" className="hidden" accept="video/*" multiple onChange={handleFileUpload} />
                    </label>
                    <button className='upload-btn mt-4 flex items-center justify-center rounded-lg cursor-pointer'
                    onClick={handleVideoUpload}
                    >
                      Upload
                    </button>
                  </div>
                </div>
                <div className="file-previews p-4 grid grid-cols-4 gap-8 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-6">
                  {showProgress && <div className="progress-bar col-span-full">
                      <div className="progress">
                      <span className='mx-2'>{fileCount}/{uploadedVideos.length}</span>
                          <div className="progress-fill bg-[--accent-1] px-2 rounded-2xl text-white" style={{ width: `${progress}%`}}>
                              {progress.toFixed(2)}%
                          </div>
                      </div>
                  </div>}
                  {/* should have 4 videos instead of 3 */}
                  {uploadedVideos.map((file, index) => (
                    <VideoPreview key={index} file={file} onRemove={handleRemoveVideo} />
                  ))}
                </div>
              </>
            ) : (
              <div className='flex flex-col justify-center items-center my-32'>
                <div className='upload-info-container'>
                  <div className='upload-info flex flex-col items-center justify-center'>
                    <h3 className='upload-title text-2xl font-bold'>Drag Files or Click Below</h3>
                    <h4 className='upload-note text-xl font-medium'>For best results, use files less than 512MB</h4>
                  </div>
                </div>
                <div className='browse-btn-container'>
                  <label htmlFor="file-browse" className="browse-btn mt-4 flex items-center justify-center rounded-lg cursor-pointer">
                    <span>Browse</span>
                    <input id="file-browse" type="file" className="hidden" accept="video/*" multiple onChange={handleFileBrowsing} />
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className='border border-[--app-border] p-4 rounded-2xl w-full'>
            <div className='flex items-center justify-between'>
              <div>
                <img width={256} src='/instagram_logo.png' />
              </div>
              <div className='flex items-center justify-end gap-x-2'>
                {connect && (
                  <div>
                    <input type='text' placeholder='Enter your username' className='outline-none px-3 py-2 rounded-lg border border-[--app-border]' value={instaUser} onChange={handleUserInput} />
                  </div>
                )}
                <div>
                  {connect ? (<button className='bg-[--accent-1] rounded-2xl px-3 py-2 text-white font-semibold' onClick={handleFetchReelClick}>
                    Fetch
                  </button>) : (
                    <button className='bg-[--accent-1] rounded-2xl px-3 py-2 text-white font-semibold' onClick={handleInstagramConnect}>
                      Connect
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='show-instagram-reels border border-[--app-border] p-4 rounded-2xl w-full my-4'>
            {isLoading ? (
              <div>
                Loading...
              </div>
            ) : (
              instaVids.length > 0 ? (
                <div className='grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4'>
                  <div className='col-span-full flex justify-between'>
                    <div>
                    <input
                        type="checkbox"
                        checked={selectedVideos.length === instaVids.length}
                        onChange={() => selectAllInstaVideos(instaVids)}
                      /><lable className="mx-3">Select All</lable>
                    </div>
                    <button className="export-all-button bg-[--accent-1] text-white rounded-2xl font-bold my-1 px-2 py-1"
                      onClick={() => exportSelectedInstaVideos(instaVids)}
                    >
                      Export Selected
                    </button>
                  </div>
                  { showInstaUploadProgress && <div className="progress-bar col-span-full">
                      <div className="progress">
                      <span className='mx-2'>{fileCount}/{selectedVideos.length}</span>
                          <div className="progress-fill bg-[--accent-1] px-2 rounded-2xl text-white" style={{ width: `${progress}%`}}>
                              {progress.toFixed(2)}%
                          </div>
                      </div>
                  </div>}
                  {instaVids.map((reel) => (
                    <div key={reel}>
                      {/* can't display thumbnail because of hash? */}
                      {/* <img height={300} width={150} src={reel.thumbnail_url} /> */}
                      <input
                        type="checkbox"
                        checked={(selectedVideos.includes(reel))}
                        onChange={() => handleCheckboxChange(reel)}
                      />
                      <video width="320" height="240" controls>
                        <source src={reel} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      {/* <button className="modal-continue-button bg-[--accent-1] text-white px-2 py-1 rounded-2xl  font-bold my-1"
                      onClick={() => exportInstaVideo(reel)}
                      >Export</button> */}
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  Nothing to display here!
                </div>
              )
            )}
          </div>

        </div>
      </div>
    </div>
  );
}