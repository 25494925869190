import React, { useEffect, useState } from 'react'
import './profile.css'
import api from '../../axiosConfig';
import {toast} from "react-toastify";

export default function ProfileSection() {
  const [changePassword, setChangePassword] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [warning, setWarning] = useState(false);
  const [showNameUpdateBtn, setShowNameUpdateBtn] = useState(false);

  const handleChangePasswordBtn = () => {
    setChangePassword(true);
    setResetPassword(false);
    setWarning(false);
  }

  const handleResetPasswordBtn = () => {
    setResetPassword(true);
    setChangePassword(false);
  }

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleFirstNameChange = (e) => {
    setShowNameUpdateBtn(true);
    setFirstName(e.target.value);
  }

  const handleLastNameChange = (e) => {
    setShowNameUpdateBtn(true);
    setLastName(e.target.value);
  }

  const saveChangePassword = async () => {
    if (newPassword === "" || confirmPassword === "") {
      setWarning(true);
    } else {
      try {
        const response = await api.post('/auth/password/change/', {
          new_password1: newPassword,
          new_password2: confirmPassword,
        })
        setChangePassword(false);
        setNewPassword("");
        setConfirmPassword("");
        toast.success("Password Updated Successfully");
        console.log(response.data.detail);
      } catch (error) {
        console.error("Error while changing password:", error);
      }
    }
  };

  const handlefNamelNameSave = () => {
    if (firstName === "" || lastName === "") {
      setWarning(true);
    } else {
      api.patch('/auth/user/', {
        first_name: firstName,
        last_name: lastName
      }).then((response) => {
        setShowNameUpdateBtn(false);
        toast.success("Name Updated Succesfully");
        setFirstName(response.data.first_name)
        setLastName(response.data.last_name)
      }).catch((error) =>{
        console.log(error);
        toast.error("Error while updating name, please try again later!")
      })
    }
  };

  useEffect(() => {
    api.get('/auth/user/')
      .then((response) => {
        const userData = response.data;
        setFirstName(userData.first_name);
        setLastName(userData.last_name);
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });
  }, []);

  return (
    <div className='w-[100%] h-[100%] mt-16'>
      <div className="profile-head mb-6">
        <div className="head-title"><h1 className='text-3xl font-bold'>Profile</h1></div>
      </div>
      <div className="profile-body">
        <div className="profile-head flex items-center justify-between">
          <h1 className='text-lg font-semibold'>Manage Profile</h1>
        </div>
        <div className="profile-sections flex items-start justify-between mt-6">
          <div className="body-sections p-5 w-1/3">
            <div className='flex items-center justify-between'>
              <div className="headings text-xl font-semibold py-2">Personal Details</div>
              {firstName && lastName && showNameUpdateBtn? (<button className='px-3 py-2 rounded-2xl bg-[--accent-1] text-white font-semibold' onClick={handlefNamelNameSave}>Save Changes</button>) : ""}
            </div>
            <div className='flex flex-col gap-y-2 mt-4 w-full py-2'>
              <input type="text" className="profile-input px-5 py-3 rounded-md border border-[--app-border] outline-none" placeholder='First Name' value={firstName} onChange={handleFirstNameChange} ></input>
              {warning && firstName === "" ? <span className="warning font-medium text-red-600">First Name is required</span> : ""}
              <input type="text" className="profile-input px-5 py-3 rounded-md border border-[--app-border] outline-none" placeholder='Last Name' value={lastName} onChange={handleLastNameChange} ></input>
              {warning && lastName === "" ? <span className="warning font-medium text-red-600">Last Name is required</span> : ""}
              <input type="text" className="profile-input px-5 py-3 rounded-md border border-[--app-border] outline-none hidden" placeholder='architprasar@gmail.com'></input>
            </div>
          </div>
          <div className="body-sections p-5 w-1/3 hidden">
            <div className="headings text-lg font-semibold">Companies</div>
            <div className="profile-input mt-2 px-2 py-3 flex items-center border border-[--app-border] rounded-md relative">
              <div className="company-avatar absolute top-50 left-50 bg-[--accent-1] px-3 py-1 text-white font-semibold rounded-md">A</div>
              <h4 className='px-14 font-medium'>architprasar@gmail.com</h4>
            </div>
            <button className="profile-section-button px-3 py-2 rounded-2xl bg-[--accent-1] text-white font-semibold w-fit mt-2">Add New Company</button>
          </div>
          <div className="body-sections p-5 w-1/3">
            <div className="headings text-lg font-semibold">Password</div>
            <h3>You can change your password here</h3>
            <div className='flex items-center justify-around mt-2'>
              <button className="profile-section-button px-3 py-2 rounded-2xl bg-[--accent-1] text-white font-semibold w-fit" onClick={handleChangePasswordBtn}>Change Password</button>
              <button className="profile-section-button px-3 py-2 rounded-2xl bg-[--accent-1] text-white font-semibold w-fit hidden" onClick={handleResetPasswordBtn}>Reset Password</button>
            </div>

            <div className='password-change-reset-section'>
              {changePassword && (
                <div className='flex flex-col items-center gap-y-2 mt-6 border border-[--app-border] rounded-2xl p-5'>
                  <input type='password' className='px-5 py-3 rounded-md border border-[--app-border] outline-none w-full' placeholder='*New Password' value={newPassword}
                    onChange={handleNewPasswordChange}></input>
                  {warning && newPassword === "" ? <span className="warning font-medium text-red-600">This field is required</span> : ""}
                  <input type='password' className='px-5 py-3 rounded-md border border-[--app-border] outline-none w-full' placeholder='*Confirm New Password' value={confirmPassword}
                    onChange={handleConfirmPasswordChange}></input>
                  {warning && confirmPassword === "" ? <span className="warning font-medium text-red-600">This field is required</span> : ""}
                  <button className="px-3 py-2 bg-[--accent-1] text-white font-semibold rounded-2xl outline-none mt-2" onClick={saveChangePassword}>Change</button>
                </div>
              )}

              {/* TODO */}
              {/* {resetPassword && (
                <h3>Reset Password</h3>
              )} */}
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
