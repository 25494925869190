import api from '../../axiosConfig'
function SaveCollection(title, videos, navigate) {
    let videos_id = videos.map(v => v.id)
    console.log(videos_id)
    const parms = {
        "name": title,
        "description": "string",
        "videos": videos_id,
        "config": {
            "comments_enabled": false,
            "likes_enabled": false,
            "poll": {
                "show": false,
                "options": {
                    "additionalProp1": "string",
                    "additionalProp2": "string",
                    "additionalProp3": "string"
                }
            },
            "style": {
                "additionalProp1": "string",
                "additionalProp2": "string",
                "additionalProp3": "string"
            },
            "type": "WIDGET"
        }
    }

    console.log(parms)
    const res = api.post('/collections/', parms).then(function (response) {
        navigate('/collection')
        return response.data
    }).catch(function (error) {
        console.log(error)
    })
    return res

}
function putCollection(title, videos, navigate, id) {
    let videos_id = videos.map(v => v.id)
    const parms = {
        "name": title,
        "description": "string",
        "videos": videos_id,
        "config": {
            "comments_enabled": false,
            "likes_enabled": false,
            "poll": {
                "show": false,
                "options": {
                    "additionalProp1": "string",
                    "additionalProp2": "string",
                    "additionalProp3": "string"
                }
            },
            "style": {
                "additionalProp1": "string",
                "additionalProp2": "string",
                "additionalProp3": "string"
            },
            "type": "WIDGET"
        }
    }

    console.log(parms)
    const res = api.put('/collections/' + id + "/", parms).then(function (response) {
        navigate('/collection')
        return response.data
    }).catch(function (error) {
        console.log(error)
    })
    return res

}

export { putCollection }
export default SaveCollection