import React from "react";

import { IoClose } from "./icon.js"


const Modal = (props) => {


    const toggleModal = () => {
        props.setIsOpen(!props.isOpen);
        if (props.onModalClose) {
            props.onModalClose();
        }
    };

    return (
        <div>
            {props.isOpen && (
                <div className="modal-overlay">
                    <div className="modal-content" style={props.style}>
                        <button className="close-btn" onClick={toggleModal}><IoClose></IoClose></button>
                        {props.children}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Modal