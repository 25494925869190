import api from "../../axiosConfig.js";

async function getPreSignedUrl(key) {
    try {
        const response = await api.get(`/auth/get-pre-signed-url/${key}`)
        return response.data;
    } catch (error) {
        console.error('Error fetching pre-signed URL:', error.response ? error.response.data : error.message);
        return null;
    }
}

async function putPreSignedUrl(fileExt) {
    try {
        const response = await api.get(`/auth/put-pre-signed-url/${fileExt}`)
        return response.data;
    } catch (error) {
        console.error('Error fetching pre-signed URL:', error.response ? error.response.data : error.message);
        return null;
    }
}

async function uploadImageFile(file, setProgress, setImage) {
    if (!file) {
        console.error('No file selected.');
        return;
    }
    const file_ext = file.type.split("/")[1];
    const response = await putPreSignedUrl(file_ext);
    const preSignedUrl = response.url;
    const key = response.key;
    if (preSignedUrl) {
        try {
            uploadFileToS3Bucket(file, preSignedUrl, function (percent) {
                setProgress(Math.floor(percent));
            })
                .then(function (preSignedUrl) {
                    console.log('File uploaded successfully:', preSignedUrl);
                    setImage(key);
                })
                .catch(function (error) {
                    console.error('Error uploading file:', error.message);
                });

        } catch (error) {
            console.error('Error uploading file to S3:', error.response ? error.response.data : error.message);
        }
    } else {
        console.log('Failed to get pre-signed URL.');
    }
}
async function uploadVideoFile(file, setProgress, setVidUrl, setVideoUrl) {
    if (!file) {
        console.error('No file selected.');
        return;
    }
    const fileExt = "mp4";
    const response = await putPreSignedUrl(fileExt);
    const preSignedUrl = response.url;
    const key = response.key;
    if (preSignedUrl) {
        uploadFileToS3Bucket(file, preSignedUrl, function (percent) {
            setProgress(Math.floor(percent));
        })
            .then(async function (preSignedUrl) {
                console.log('File uploaded successfully:', preSignedUrl);
                setVidUrl(key);
                const res = await getPreSignedUrl(key);
                setVideoUrl(res.url);
            })
            .catch(function (error) {
                console.error('Error uploading file:', error.message);
            });

    } else {
        console.log('Failed to get pre-signed URL.');
    }
}

async function uploadImageFromUrl(file, imageUrl, setProgress, setImage) {
    try {
        if (!file) {
            console.error('No file selected.');
            return;
        }
        const fileParts = imageUrl.split("?")[0].split("/");
        const fileName = fileParts[fileParts.length - 1];
        let file_ext = fileName.includes(".") ? fileName.split(".")[1] : "unknown";
        if (file_ext === "jpg" || file_ext === "unknown") {
            file_ext = "jpeg"
        }
        const response = await putPreSignedUrl(file_ext);
        const preSignedUrl = response.url;
        const key = response.key;
        if (preSignedUrl) {
            try {
                uploadFileToS3Bucket(new Blob([file.imageResponse]), preSignedUrl, function (percent) {
                    setProgress(Math.floor(percent));
                })
                    .then(function (preSignedUrl) {
                        console.log('File uploaded successfully:', preSignedUrl);
                        setImage(key);
                    })
                    .catch(function (error) {
                        console.error('Error uploading file:', error.message);
                    });

            } catch (error) {
                console.error('Error uploading file to S3:', error.response ? error.response.data : error.message);
            }
        } else {
            console.log('Failed to get pre-signed URL.');
        }
    } catch (error) {
        console.error('Error uploading image to S3:', error);
        throw error;
    }
}

function uploadFileToS3Bucket(file, preSignedUrl, progressCallback) {
    return new Promise(function (resolve, reject) {

        const xhr = new XMLHttpRequest();

        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    resolve(xhr)
                }
                else {
                    reject(xhr)
                }
            }
        };

        if (progressCallback) {
            xhr.upload.onprogress = (e) => {
                if (e.lengthComputable) {
                    var percentComplete = (e.loaded / e.total) * 100;
                    progressCallback(percentComplete);
                }
            };
        }
        xhr.open("PUT", preSignedUrl);
        xhr.send(file);
    });
}

export { uploadImageFromUrl, uploadImageFile, uploadVideoFile, getPreSignedUrl,putPreSignedUrl, uploadFileToS3Bucket}