import api from '../../axiosConfig'
export function patchCollection(value, id, callback) {
    const parms = {
        "config": {
            "comments_enabled": false,
            "likes_enabled": false,
            "poll": {
                "show": false,
                "options": {
                    "additionalProp1": "string",
                    "additionalProp2": "string",
                    "additionalProp3": "string"
                }
            },
            "style": {
                "additionalProp1": "string",
                "additionalProp2": "string",
                "additionalProp3": "string"
            },
            "type": value
        }
    }
    const res = api.patch('/collections/' + id + "/", parms).then(function (response) {
        callback()
        console.log("response.data:", response.data);
        return response.data
    }).catch(function (error) {
        console.log(error)
    })
    return res
}

export async function deleteCollection (id, callback) {
    try {
        const response = await api.delete(`/collections/${id}/`);
        callback();
        return response.data;
    } catch (error) {
        console.error("Error deleting collection:", error);
    }
};

// export default patchCollection;