import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import * as countriesGeoJson from './countries.geo.json';

const Map = ({countriesData}) => {
  const [countries, setCountries] = useState({});
  const countriesRef = useRef(countries);

  useEffect(() => {
    const newCountries = countriesData.reduce((obj, item) => {
      obj[item.country] = item.count;
      return obj;
    }, {});
    setCountries(newCountries);
    countriesRef.current = newCountries;
  }, [countriesData]);

  const [map, setMap] = useState(null);

  const highlightCountry = (feature, layer) => {
    const maxCount = Math.max(...Object.values(countriesRef.current));
    if (Object.keys(countriesRef.current).includes(feature.properties.name)) {
      layer.setStyle({
        fillColor: `rgb(128, 0, 128, ${countriesRef.current[feature.properties.name] / maxCount})`,
        weight: 2,
        opacity: countriesRef.current[feature.properties.name] / Math.max(Object.values(countriesRef.current)),
        color: 'white',
        dashArray: '3',
        fillOpacity: 0.7
      });

      layer.on({
        mouseover: (e) => {
          const layer = e.target;
          layer.setStyle({
            weight: 5,
            color: '#666',
            dashArray: '',
            fillOpacity: 0.7
          });
          layer.bindPopup(`${feature.properties.name}<br>${countriesRef.current[feature.properties.name]}`).openPopup();
        },
        mouseout: (e) => {
          e.target.setStyle({
            weight: 2,
            color: 'white',
            dashArray: '3',
            fillOpacity: 0.7
          });
          e.target.closePopup();
        }
      });
    }
  };

  return (
    <MapContainer
      center={[30, 0]}
      zoom={3}
      className='map-root'
      whenCreated={setMap}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <GeoJSON
        key={JSON.stringify(countries)}
        data={countriesGeoJson}
        style={{ color: 'rgba(255, 255, 255, 0)' }}
        onEachFeature={highlightCountry}
      />
    </MapContainer>
  );
};

export default Map;