import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './datePicker.css'; // Add custom styles for the date picker

const DateRangePicker = ({ onDateChange, startDate, endDate }) => {
  const handleStartDateChange = (date) => {
    onDateChange(date, endDate);
  };

  const handleEndDateChange = (date) => {
    onDateChange(startDate, date);
  };

  return (
    <div className="date-picker-root">
      <div className="date-picker-container">
        <label className="date-picker-label">From:</label>
        <DatePicker
          selected={startDate}
          onChange={handleStartDateChange}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          className="date-picker-input"
        />
      </div>
      <div className="date-picker-container">
        <label className="date-picker-label">To:</label>
        <DatePicker
          selected={endDate}
          onChange={handleEndDateChange}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          className="date-picker-input"
        />
      </div>
    </div>
  );
};

export default DateRangePicker;