import axios from 'axios';
import 'react-simple-toasts/dist/theme/dark.css';
import 'react-simple-toasts/dist/theme/failure.css';
import { logout } from './organizer/LoginRegister/logic';
import { toast } from "react-toastify";


const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

const refreshToken = async () => {
    // throw new Error("Deliberate error for testing");
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
        throw new Error("No refresh token available!");
    }
    
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/token/refresh/`, {
            "refresh": refreshToken
        });

        const newAccessToken = response.data.access;
        localStorage.setItem('accessToken', newAccessToken);

        return newAccessToken;
    } catch (error) {
        if (error.response) {
            console.log("RT Data:", error.response.data);
            console.log("RT Status:", error.response.status);
            console.log("RT Headers", error.response.headers);
        } else if (error.request) {
            console.log("RT Request:", error.request);
        } else {
            console.log('RT Error', error.message);
        }
        console.log("RT Config", error.config);
        throw error; // rethrow the error
    }
};

const isTokenExpired = (accessToken) => {
    if (accessToken) {
        const expiryTime = JSON.parse(atob(accessToken.split(".")[1])).exp * 1000 - 60000; // 1 min before actual expiry
        const currentTime = Date.now();
        // console.log("currentTime - expiryTime =", (currentTime - expiryTime));
        return currentTime >= expiryTime;
    } else {
        console.log("No token received");
        return false;
    }
};

api.interceptors.request.use(
    async (config) => {
        let accessToken = localStorage.getItem('accessToken');

        if (accessToken && isTokenExpired(accessToken)) {
            try {
                // console.log("Resetting accessToken...");
                accessToken = await refreshToken();
            } catch (err) {
                console.error(err);
                await logout();
                return Promise.reject(new Error(err));
            }
        }

        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(new Error(error));
    }
);

api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        console.log("err ->", error);
        if (error.response.status !== undefined && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const newAccessToken = await refreshToken();

                originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

                return api(originalRequest);
            } catch (refreshError) {
                await logout();
                console.error('Error refreshing token:', refreshError);
            }
        }

        if (error.response.status === 400) {
            if(error.response.data){
                const data = error.response.data;
                let msg = '';
                Object.keys(data).forEach(function(key) {
                    const val = data[key]
                    msg += val + '\n';
                });
                if(msg){
                    toast.error(msg);
                }
            }
        }
        if (error.response.status === 404) {
            if(error.response.data){
                const data = error.response.data;
                let msg = '';
                Object.keys(data).forEach(function(key){
                    const val = data[key]
                    msg+=val + '\n';

                })
                if(msg)
                    toast.error(msg);
            }
        }
        return Promise.reject(new Error(error));
    }
);

export default api;