import React from 'react';

function EmailVerificationMessage() {

    const handleLoginClick = ()=>{
        window.location.href = "/";
    }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-md w-full bg-white p-8 rounded shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Email Verification</h2>
        <p className="mb-6">An email has been sent to your email address. Kindly verify it to proceed with login.</p>
        <div className="flex justify-between">
          {/* <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            Resend Email
          </button> */}
          <button className="profile-section-button px-3 py-2 rounded-2xl bg-[--accent-1] text-white font-semibold w-fit mt-2" onClick={handleLoginClick}>
            Login
          </button>
        </div>
      </div>
    </div>
  );
}

export default EmailVerificationMessage;
