import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function MenuButton({ Icon, goto, name }) {
    return (
        <Link to={goto}>
            <div className={`menu-item`}>
                <span>{Icon}</span> <span className="menu-text">{name}</span>
            </div>
        </Link>
    );
}
