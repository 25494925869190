import React from 'react'
import { Link } from 'react-router-dom'
import { AddSquare } from "iconsax-react"
import Analytics from '../analytics/analytics'

import './home.css'
import './background.css'

/**
 * 
 * TODO:
 * Fix spacing around the page
 * Fix structure of page 
 */

export default function Home() {
  return (
    <>
      <div className="homeHead">
        <div className="headTitle"><h1 className='text-4xl font-bold'>HOME</h1></div>

        <Link to="/createvideo">
          <button className="home-CreateButton">
            <AddSquare className='add-square-icon' size={'1.75rem'} variant='Outline' />
            <span>CREATE</span>
          </button>
        </Link>
      </div>
      <div className='analytics-container'>
        <Analytics />
      </div>
    </>
  )
}
