import React, { useState } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import Metrics from './metrics';

const Overall = ({data}) => {
  const allMetrics = {
    0: {
      totalViews: 'Total Views',
      uniqueViews: 'Unique Views',
      viewDuration: 'View Duration',
      repeatViews: 'Repeat Views',
    },
    1: {
      ctr: 'Click Through Rate',
      conversionRate: 'Conversion Rate',
    },
    2: {
      completionRate: 'Completion Rate',
      dropOffPoints: 'Drop Off Points',
      rewatchFrequency: 'Rewatch Frequency',
      fullScreenViews: 'Full Screen Views',
    },
    3: {
      checkouts: 'Checkouts',
      clicks: 'Clicks',
    },
    4: {
      bufferringInstance: 'Bufferings',
      loadTime: 'Load Time',
    }
  }

  const [selectedMetrics, setSelectedMetrics] = useState({0: 'Total Views', 1: 'Click Through Rate', 2: 'Completion Rate', 3: 'Clicks', 4: 'Bufferings'});
  const [displayIndex, setDisplayIndex] = useState(0);
  
  const dataLength = data ? data[selectedMetrics[displayIndex]]?.data?.length : 0;
  const tickInterval = Math.floor(dataLength / 5);
  const ticks = data ? data[selectedMetrics[displayIndex]].data
    .filter((_, index) => index % tickInterval === 0)
    .map(item => item.x) : [];
  const formatXAxisTick = (tick) => {
    const date = new Date(tick);
    const options = { day: '2-digit', month: 'short' };
    return date.toLocaleDateString('en-GB', options).replace(' ', '-');
  };
  return (
    <div className='overall-root'>
      <div className='overall-headers'>
        {[0, 1, 2, 3, 4].map(idx => (
          <Metrics 
            key={`metric-${idx}`}
            data={data}
            index={idx}
            metrics={allMetrics[idx]}
            selectedMetrics={selectedMetrics}
            onChange={setSelectedMetrics}
            displayIndex={displayIndex}
            setDisplayIndex={setDisplayIndex}
          />
        ))}
        {/* <div className="overall-header">
          <button>{"View Full Report ->"}</button>
          <p>Legend</p>
        </div> */}
      </div>
      <div className="overall-chart">
        {
          data && 
          <LineChart  margin={{ top: 20, right: 30, left: 20, bottom: 60 }} key={displayIndex} width={1120} height={280} data={data ? data[selectedMetrics[displayIndex]]["data"] : []}>
            <Line type="linear" dataKey="y" stroke="#8884d8" strokeWidth={2} dot={{ r: 4 }} activeDot={{ r: 6 }}/>
            <CartesianGrid stroke="#ececec" vertical={false} />
            <XAxis
            dataKey="x"
            axisLine={false}
            tickLine={false}
            ticks={ticks}
            label={{ value: data[selectedMetrics[displayIndex]]["xlabel"], position: 'center', offset: -50 }} 
            tick={{ angle: -45, textAnchor: 'end', dy: 10 }}
            tickFormatter={formatXAxisTick}
          />
          <YAxis
            orientation='right'
            tickCount={3}
            axisLine={false}
            tickLine={false}
            label={{ value: data[selectedMetrics[displayIndex]]["ylabel"], angle: 90, position: 'center', offset: 20 }} 
          /><Tooltip />
          </LineChart>
        }
      </div>
    </div>
  )
}

export default Overall